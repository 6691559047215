import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {
  PortalBookingStatusTypeEnum,
  PortalOrderNode,
  PortalOrderStatusType,
} from '@app/services/api/api.types';
import { PopupsService } from '@app/ui/services/popups.service';
import { containerWidth } from '@app/utils/constants';

@Component({
  selector: 'portal-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionComponent {
  PortalBookingStatusTypeEnum = PortalBookingStatusTypeEnum;
  PortalOrderStatusType = PortalOrderStatusType;

  @Input() option: PortalOrderNode;
  @Input() portalLink: string;
  @Input() showedDetails: boolean;
  @Input() withHiddenFlightsV2: boolean;

  @Output() requestToBook = new EventEmitter();

  @ViewChild('optionDetailsTemplate') optionDetailsTemplate: TemplateRef<unknown>;

  constructor(private popupsService: PopupsService) {}

  toggleDetails() {
    if (!this.showedDetails) {
      window.track({
        event_name: 'quote_details_opened',
        flight_request_portal_link: this.portalLink,
        option_portal_link: this.option.portalLink,
      });
    }

    if (window.innerWidth < containerWidth) {
      this.popupsService.showModal(this.optionDetailsTemplate);
    } else {
      this.showedDetails = !this.showedDetails;
    }
  }
}
