import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import {
  PortalBookingStatusTypeEnum,
  PortalOrderNode,
  PortalOrderStatusType,
} from '@app/services/api/api.types';

@Component({
  selector: 'option-details-modal',
  templateUrl: './option-details-modal.component.html',
  styleUrls: ['./option-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionDetailsModalComponent {
  PortalBookingStatusTypeEnum = PortalBookingStatusTypeEnum;
  PortalOrderStatusType = PortalOrderStatusType;

  @Input() option: PortalOrderNode;
  @Input() portalLink: string;

  @Output() requestToBook = new EventEmitter();
}
